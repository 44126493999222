import React from 'react';

import ContentModuleImageText from './ContentModuleImageText';
import Section from './Common/Section';

const ContentModuleImageTextMultiple: React.FunctionComponent<ContentModuleImageTextMultipleProps> = (
  props
) => {
  const { title, submodules } = props;

  return (
    <Section nopad>
      {submodules.map((module, index) => (
        <React.Fragment key={`cm-content-image-multiple-${index}`}>
          <ContentModuleImageText
            dark={module.colour_mode === 'dark'}
            isFirstItem={index === 0}
            isMulti
            multiTitle={title}
            isReverse={module.image_align === 'right'}
            panelContent={module.content}
            panelImage={module.image}
          />
        </React.Fragment>
      ))}
    </Section>
  );
};

interface ContentModuleImageTextMultipleProps {
  title?: string;
  submodules: {
    colour_mode: string;
    content: string;
    image: any;
    image_align: string;
    title: string;
  }[];
}

export default ContentModuleImageTextMultiple;
